<template>
  <q-drawer
    v-model="drawer"
    :mini="miniDrawer"
    side="left"
    :width="240"
    :mini-width="52"
  >
    <div id="drawer">
      <!-- menus -->

      <Menu
        v-for="menu in menus"
        :key="menu.id"
        :menu="menu"
        :is-active="menu.route === activeMenu"
        @click="goto(menu.route)"
      />

      <!-- ... -->

      <q-space />

      <!-- help -->

      <Menu
        :menu="{
          label: 'help center',
          icon: 'mdi-help-circle-outline',
          activeIcon: 'mdi-help-circle',
        }"
        @click="goto('helpCenter')"
      />

      <!-- ... -->
    </div>
  </q-drawer>
</template>

<script>
import Menu from "./components/Menu.vue";

export default {
  name: "Drawer",

  components: { Menu },

  data() {
    return {
      drawer: true,
      miniDrawer: true,
      menus: [
        {
          id: this.$nano.id(),
          label: "overview",
          route: "capture-overview",
          icon: "o_dashboard",
          activeIcon: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "scan/import",
          route: "capture-scan-import",
          icon: "eva-printer-outline",
          activeIcon: "eva-printer",
        },
        {
          id: this.$nano.id(),
          label: "folders",
          route: "repositories-browse",
          icon: "eva-archive-outline",
          activeIcon: "eva-archive",
        },
        {
          id: this.$nano.id(),
          label: "settings",
          route: "capture-settings",
          icon: "eva-settings-2-outline",
          activeIcon: "eva-settings-2",
        },
      ],
      activeMenu: "",
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.activeMenu = this.$route.meta.menu;
      },
    },
  },

  methods: {
    goto(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style lang="scss" scoped>
#drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--component-bg-color);
}
</style>

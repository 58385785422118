<template>
  <div id="account">
    <!-- <div id="company-logo">
      <img src="@/assets/google.png" alt="logo" />
    </div> -->

    <div id="user-avatar">
      <img src="@/assets/avatar/avatar.png" alt="avatar" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Account",
};
</script>

<style lang="scss" scoped>
#account {
  // display: flex;
  // align-items: center;
  // background-color: var(--body-bg-color);
  // border-radius: 4px;
  // padding: 4px 8px;

  &:hover {
    cursor: pointer;
  }

  #company-logo {
    height: 24px;
    margin-right: 16px;
  }

  #user-avatar {
    height: 40px;

    img {
      border-radius: 100%;
    }
  }
}
</style>

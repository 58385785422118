<template>
  <q-layout view="hHh LpR fFf">
    <!-- toolbar -->

    <Toolbar />

    <!-- ... -->

    <!-- drawer -->

    <Drawer />

    <!-- ... -->

    <!-- page content -->

    <q-page-container>
      <div id="page-wrapper">
        <slot name="default"></slot>
      </div>
    </q-page-container>

    <!-- ... -->
  </q-layout>
</template>

<script>
import Toolbar from "./components/toolbar/Toolbar.vue";
import Drawer from "./components/drawer/Drawer.vue";

export default {
  name: "AppLayout",

  components: { Toolbar, Drawer },
};
</script>

<style lang="scss" scoped>
#page-wrapper {
  position: relative;
  min-height: calc(100vh - 56px);
  padding: 16px;
}
</style>

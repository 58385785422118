import { render, staticRenderFns } from "./Toolbar.vue?vue&type=template&id=ed29bbec&scoped=true&"
import script from "./Toolbar.vue?vue&type=script&lang=js&"
export * from "./Toolbar.vue?vue&type=script&lang=js&"
import style0 from "./Toolbar.vue?vue&type=style&index=0&id=ed29bbec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed29bbec",
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QSpace});

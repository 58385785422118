<template>
  <q-header id="toolbar">
    <q-toolbar class="no-padding" style="height: 56px">
      <Legend />

      <Logo class="q-ml-md q-mr-xl" />

      <q-space />

      <ThemeSwitcher class="q-mr-sm" />

      <Notifications class="q-mr-sm" />

      <BaseActionButton
        is-flat
        icon="eva-power-outline"
        no-border
        class="q-mr-sm"
        @click="logOut"
      />

      <Account class="q-mr-md" />
    </q-toolbar>
  </q-header>
</template>

<script>
import Legend from "./components/Legend.vue";
import Logo from "./components/Logo.vue";
import ThemeSwitcher from "./components/ThemeSwitcher.vue";
import Notifications from "./components/Notifications.vue";
import Account from "./components/Account.vue";

export default {
  name: "Toolbar",

  components: {
    Legend,
    Logo,
    ThemeSwitcher,
    Notifications,
    Account,
  },

  methods: {
    logOut() {
      window.localStorage.clear();
      this.$router.push({ name: "signIn" });
    },
  },
};
</script>

<style lang="scss" scoped>
#toolbar {
  background-color: var(--component-bg-color);
}
</style>

<template>
  <BaseActionButton is-flat icon="mdi-bell-outline" no-border>
    <Pulse class="pulse" />
  </BaseActionButton>
</template>

<script>
import Pulse from "@/components/common/Pulse.vue";

export default {
  name: "Notifications",

  components: { Pulse },
};
</script>

<style lang="scss" scoped>
.pulse {
  position: absolute;
  top: 4px;
  right: 4px;
}
</style>

<template>
  <div
    id="menu"
    v-tooltip:secondary.right="menu.label"
    :class="{ active: isActive }"
    v-on="$listeners"
  >
    <BaseIcon :name="isActive ? menu.activeIcon : menu.icon" color="" />

    <div v-if="isActive" class="indicator"></div>
  </div>
</template>

<script>
export default {
  name: "Menu",

  props: {
    menu: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#menu {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-color);
  transition: all 0.25s ease;
  position: relative;

  &:hover {
    cursor: pointer;
    color: var(--secondary);
    background-color: var(--hover-bg-color);
  }

  &.active {
    color: var(--primary);
  }

  .indicator {
    position: absolute;
    top: calc(50% - 18px);
    left: 0;
    height: 36px;
    width: 3px;
    background-color: var(--primary);
    border-radius: 0px 4px 4px 0px;
  }
}
</style>

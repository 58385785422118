<template>
  <div id="logo">
    <img :src="_logo" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Logo",

  computed: {
    ...mapState(["darkTheme"]),

    _logo() {
      return require(`@/assets/logo/name-${
        this.darkTheme ? "dark" : "light"
      }.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
#logo {
  display: flex;
  align-items: center;

  img {
    height: 24px;
  }
}
</style>

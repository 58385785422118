<template>
  <AppLayout>
    <router-view />
  </AppLayout>
</template>

<script>
import AppLayout from "./layout/AppLayout.vue";

export default {
  name: "Capture",

  components: { AppLayout },
};
</script>

<style lang="scss" scoped></style>
